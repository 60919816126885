<template>
  <div id="main"></div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    dates: {
      type: Array,
      default: () => [],
    },
    lines: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      const myChart = this.$echarts.init(document.getElementById("main"));
      this.initChart();
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    });
  },
  methods: {
    initChart() {
      const myChart = this.$echarts.init(document.getElementById("main"));
      myChart.showLoading();
      myChart.setOption(this.echart_Option);
      myChart.hideLoading();
    },
  },
  computed: {
    echart_Option() {
      let datas = [];
      const line = this.lines,
        data = this.data,
        time = this.dates;
      line.map((item, index) => {
        datas.push({
          name: item,
          type: "line",
          data: data[index],
          areaStyle: {},
          smooth: true,
        });
      });
      let option = {
        color:['#FF3B00','#509DED'],
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross" },
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
            magicType: { type: ["line", "bar", "stack", "tiled"] },
          },
        },
        legend: {
          data: line,
          selectedMode: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisTick: {
              alignWithLabel: true,
            },
            splitLine: {
              show: false,
            },
            data: time,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            position: "left",
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: datas,
      };
      return option;
    },
  },
  watch: {
    data: {
      handler() {
       this.initChart()
      },
      deep:true
    },
  },
};
</script>

<style lang="less" scoped>
#main {
  width: 100%;
  height: 422px;
}
</style>